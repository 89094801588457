import React, { Component } from 'react'

export class JwPlayerDrmProtected extends Component {
state = {
  playingfromtime:false
}
componentDidMount(){
 
this.playDrmProtectedContentJwPlayer(this.props.url, this.props.subtitles , this.props.licenseUrl ,  this.props.licenseToken);
}

componentDidUpdate(prevProps) {
  if (this.props.videoId !== prevProps.videoId) {
    window.jwplayer().stop()
    this.playDrmProtectedContentJwPlayer(this.props.url, this.props.subtitles , this.props.licenseUrl ,  this.props.licenseToken);

  
  }
}

 

 playDrmProtectedContentJwPlayer(url , subtitle, licenseUrl,licenseToken ){
  const jwplayer = window.jwplayer;
 
    var subtitlegenerate = [];
  var count = 0;
//   [{
//             "kind": "captions",
//             "file": "https://cchoichoi.viewlift.com/2020/12/1608225960276_taqdeer_ep_2_bng_sub.vtt",
//             "label": "English"
//           },   
//           {
//             "kind": "captions",
//             "file": "https://www.yourdomain.com/caption-file_sp.vtt",
//             "label": "Español"
//         }],

  for(var i = 0 ; i<subtitle.length ; i++){
 
    if(subtitle[i].format == 'VTT'){
      if(count == 0){
  subtitlegenerate.push({kind:'captions', file: subtitle[i].url, label: subtitle[i].language });
}else{
  subtitlegenerate.push( {kind:'captions', file: subtitle[i].url, label: subtitle[i].language});
}
count++;
    }

  }
  
var playerConfig = {
  width: "100%",
  height: "260",
  aspectratio: "16:9",
        "playlist": [
          {
            
            "default": true,
                "type": "mpd",
                "file": url,
				"tracks":  subtitlegenerate,
                "drm": {
                  "widevine": {
                    "url": licenseUrl,
                    "headers": [
                      {
                        "name": "X-AxDRM-Message",
                        "value":  licenseToken
                      }
                    ]
                  },
                  //    "playready": {
                  //     "url": "https://drm-playready-licensing.axprod.net/AcquireLicense",
                  //   "headers": [
                  //     {
                  //       "name": "X-AxDRM-Message",
                  //       "value": licenseToken
                  //     }
                  //   ]
                  // },

                //   "fairplay": {
                //     "processSpcUrl": "https://drm-fairplay-licensing.axprod.net/AcquireLicense",
                //     "certificateUrl": "https://djd1mr67a7xjn.cloudfront.net/certs/fairplay.cer",
                //     "url":"https://drm-fairplay-licensing.axprod.net/AcquireLicense",
                //     "headers": [
                //       {
                //         "name": "X-AxDRM-Message",
                //         "value": licenseToken
                //       }
                //     ]
                // }
              //   "fairplay": {
              //     "certificateUrl":  "https://djd1mr67a7xjn.cloudfront.net/certs/fairplay.cer",
              //     "processSpcUrl": function (initData) {
              //         return "https://" + initData.split("skd://").pop();
              //     },
              //     "licenseRequestHeaders": [
              //         {
              //             "name": "Content-type",
              //             "value": "arraybuffer"
              //         },
              //         {
              //             "name": "X-AxDRM-Message",
              //             "value": licenseToken
              //         }
              //     ]
              // }
               
                  
                },
                "label": "1"
             
          }
        ],
        autostart: true
      };
jwplayer("jwplayerDiv").setup(playerConfig);

let totalTimeWatched = 0;
let previousPosition = 0;
if(window.jwplayer()){
  var pos = 0;
  if(localStorage.getItem(this.props.videoId)){
    pos = parseFloat(localStorage.getItem(this.props.videoId));
  } 
    setTimeout(()=>{
      if(pos!=0){
window.jwplayer().seek(pos);
      }
    
        jwplayer().on('time', (e) => {
             const { position } = e;
          totalTimeWatched += (position - previousPosition);
          previousPosition = position;
            localStorage.setItem(this.props.videoId, position)
      });
       
      
    },1500)

  }

 

}



  render() {
    return (
      <>
      <div id="jwplayerDiv">  </div>
      
      </>
    )
  }
}
 
export default JwPlayerDrmProtected