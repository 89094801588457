import React, { Component } from 'react'
import MovieCard from '../../../Component/MovieCard/MovieCard'
import Layout from '../Layout/Layout'
import { useParams } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../../Component/Spinner/Spinner';
export class SearchPage extends Component {
state = {
  loading : true,
  searchName : '',
  data : '',
  
}

searchHandlerFunction = (e)=>{
this.setState({searchName :e, loading:true})
axios.get("?s="+e)
.then((response)=>{
  var data = response.data;
this.setState({data: data , loading:false})
})
}
  componentDidMount() {
    var url = window.location.href;
    var url = new URL(url);
    var params= new URLSearchParams(url.search);
    var sname = params.get("s");
  this.setState({searchName :sname});

  axios.get("?s="+sname)
  .then((response)=>{
    var data = response.data;
 this.setState({data: data , loading:false})
  })
 
}

componentDidUpdate(){
  
}
  render() {
var data = this.state.data;

var array = [];
 const renderData = Object.keys(data).map((i)=>{
console.log(data[i])
var name = data[i].title;
 
var url = '/video/watch?id='+data[i].id;
var image = data[i].img;
console.log(name)
return  <MovieCard poster ={image}
      url={url}
      title = {name}
      type = {"Movie"}  /> 

    
 
 
 })

 


    return (
        <>
        <Layout searchHandlerFunction={this.searchHandlerFunction}>
           <div class="catalog" >
            <br/><br/>
            
              <div class="container pt-5">
               <div class="row">
      
    
     
{this.state.loading?<Spinner/>: renderData }      

 
       
               </div>
              </div>
          </div>
        </Layout>
           
            
            </>
    )
  }
}

export default SearchPage
// export default (props) => (
//     <SearchPage
//         {...props}
//         params={useParams()}
//     />
// );