import React, { Component } from 'react'
import JwPlayerNoDrm from './JwPlayerNoDrm';
import axios from 'axios';
import JwPlayerDrmProtected from './JwPlayerDrmProtected';
import DashPlayerDrm from '../DashPlayerDrm/DashPlayerDrm';
import Spinner from '../../Spinner/Spinner';
export class JwPlayerIframe extends Component {

    state ={
        loading:true,
        data:"",
        url:"",
        subtitles:"",
        drmenable:false,
        licenseToken:"",
        licenseUrl:"",
        videoTime:0,
        videoId:""

    }
    componentDidMount(){
      var url = window.location.href;
    var url = new URL(url);
    var params= new URLSearchParams(url.search);
    var id = params.get("id");
  this.setState({videoId :id});

     this.gettingDataFromVideoId(id)
   
     }

     componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.videoId !== prevProps.videoId) {
        this.gettingDataFromVideoId(this.props.videoId)
        
      }
    }
    updateVideoTime = (e)=>{

    }


    gettingDataFromVideoId= (id)=>{
      axios.get("?id="+id)
      .then((response)=>{
        var data = response.data;
      
        var url =  data.url;
        var subtitles = data.subtitles;
        var drmenable = JSON.parse(data.drmenable);
        var licenseToken = data.licenseToken;
        var licenseUrl = data.licenseUrl;
        this.setState({
           data :data,
           url:url,
           subtitles:subtitles,
           loading:false,
           drmenable:drmenable,
           licenseToken:licenseToken,
           licenseUrl:licenseUrl
        })
         
  })
    }
  render() {
    return (
      <>
  
      {/* {this.state.loading?"":(
      this.state.drmenable?<JwPlayerDrmProtected  videoId={this.props.videoId} url={this.state.url} subtitles={this.state.subtitles} licenseUrl={this.state.licenseUrl} licenseToken={this.state.licenseToken}/>:
      <JwPlayerNoDrm videoId={this.props.videoId}  url={this.state.url} subtitles={this.state.subtitles} />)} */}

{this.state.loading?<Spinner/>:(
      this.state.drmenable?<JwPlayerDrmProtected  videoId={this.state.videoId} url={this.state.url} subtitles={this.state.subtitles} licenseUrl={this.state.licenseUrl} licenseToken={this.state.licenseToken}/>:
      <JwPlayerNoDrm videoId={this.state.videoId}  url={this.state.url} subtitles={this.state.subtitles} />)}
      </>
    )
  }
}

export default JwPlayerIframe