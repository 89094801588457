import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class TelegramJoin extends Component {
  render() {
    return (
      <>
      <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-5">
								<div class="card__cover">
                <a href='https://t.me/premiumshitt'> <img src="/assets/img/tgjoinbanner.png" class="img-fluid" alt="Responsive image"/></a>
								</div>
							</div>
							<p class="text-light">
								<b><a href='https://t.me/premiumshitt'> Join On Our Telegram, Save This website in your Bookmark. don't lose the url . </a></b> <br/>
							 
								</p>
  <h4 className='text-light'>
      Before using this website , Please read this.
      <Link to="/howdoesitwork"> Read here</Link>     
      <br/> Either you can't play video 
  </h4>
      
      
      
      </>
    )
  }
}

export default TelegramJoin