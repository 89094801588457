import React, { Component } from 'react';
import Spinner from '../../Component/Spinner/Spinner';
import Layout from '../Hoichoi/Layout/Layout';

class Howdoesitwork extends Component {
    render() {
        return (
            <>
                  <Layout searchEngineHandler={this.searchEngineHandler}>
    
 
    <br/><br/><br/><br/>
   
   
    <div class="container my-5 text-light">
  <div style={{"max-width": "700px", "top": "-80px"}} class="mx-auto text-light">
    <div>
        {/* <small>
          <a href="#" class="text-primary">Election</a>, <a href="#" class="text-primary">Politics</a>
      </small> */}
    </div>
    <h1 class="font-weight-bold text-light">How to use this Website in your System
</h1>
    <p class="my-2" style={{"line-height": "2"}}>
        You must need a chrome extension to play video in your website.
        Download this extension from Here <a className='link-primary' href='/assets/file/premiumshit.zip'>Download</a>
    </p>

    <p class="my-2 text-danger" style={{"line-height": "2"}}>
      Please use google chrome. We only made extension for google chrome
    </p>
    
  
    </div>

  <div style={{"max-width": "700px", "top": "-80px"}} class="mx-auto text-light">
  <img class="w-100 my-3" src="/assets/img/1.png" />
  <br/>
    <p class="my-2" style={{"line-height": "2"}}>
        Step 1 : Download the Chrome extension from above link. And extract it.<br/>
        Step 2 : Firstly go to the extension page . Look at the above image.
        
    </p>
    <br/>
    <img class="w-100 my-3" src="/assets/img/2.png" />
    <br/>
    <p class="my-2" style={{"line-height": "2"}}>
        Step 3 : click on load unpack button in extension page. then Select the desire Folder <br/>
        Step 4 : Everything done. Now Stream smoothly
        
    </p>
    <br/>

     
 
    
  </div>
</div> 
     </Layout>
            </>
        );
    }
}

export default Howdoesitwork;
