import React, { Component } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../Hoichoi/Layout/Layout';
import WatchMovie from '../Watch/WatchMovie/WatchMovie';
import axios from 'axios';
import WatchSeries from '../Watch/WatchSeries/WatchSeries';
import DownloadMlwbd from '../../Component/DownloadMlwbd/DownloadMlwbd';
export class WatchVideo extends Component {

    state = {
      type:"",
        url : "",
        id:"",
        title:"",
        descrptiton:"",
        videometaid:"",
        contenttype:"",
        videoimage:"",
        videoId:"",
        loading:true,
        seasonepisodedetails:"",
        season:"",
        dataRender :"",
        videosourceloading:true

    }
    componentDidMount(){
      const { typeWatch } = this.props.params;
           var url = window.location.href;
           var url = new URL(url);
           var params= new URLSearchParams(url.search);
           var id = params.get("id");
      this.setState({id :id ,type:typeWatch,videoId:id});
    
 
axios.get('?id='+ id)
.then((response)=>{
  var data = response.data;
  console.log(data.id);
  // var contentdetails = JSON.parse(data);
  this.setState({
    title:data.title,
    videometaid:data.id,
    videoimage:data.img,
    loading:false,

   })
   document.title = this.state.title
 
})


// axios.get('?mflixid='+ id)
// .then((response)=>{
//   var data = response.data;
//   console.log(data)
//    this.setState({
//     dataRender:data,
//     videosourceloading:false

//    })
 
// })
 
    }
  render() {
//     var sourceRender = ""
//     var data = this.state.dataRender
//     if(!this.state.videosourceloading){
//       sourceRender = Object.keys(data).map((e=>{
// return <>
// {data[e].code}
// </>
//       }))

//     }
    return (
      <>
      <Layout>
      
      {this.state.loading?"":<WatchMovie contenttype={this.state.contenttype} videoId ={this.state.videoId} poster={this.state.videoimage} description={this.state.descrptiton} title={this.state.title} url={this.state.url}/>}

     
      </Layout>
   
	
 
      </>
    )
  }
}

export default (props) => (
    <WatchVideo
        {...props}
        params={useParams()}
    />
);