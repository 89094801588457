import axios from 'axios'
import React, { Component } from 'react'
import './WatchMovie.css';
import PlayerJs from '../../../Component/VideoPlayer/Playerjs/PlayerJs';
import DashPlayer from '../../../Component/VideoPlayer/DashPlayer/DashPlayer';
import dashjs from 'dashjs';
import JwPlayer from '../../../Component/Player/JwPlayer/JwPlayer';
import TelegramJoin from '../../../Component/TelegramJoin/TelegramJoin';
import DownloadMlwbd from '../../../Component/DownloadMlwbd/DownloadMlwbd';
import StreamingSource from '../../../Component/StreamingSource/StreamingSource';
 

export class WatchMovie extends Component {
  state = {
    title:"",
    descrptiton:"",
    videometaid:"",
    contenttype:"",
    videoimage:"",
    videoId:"", 
    loading : true,
    videoload:false

  }
  componentDidMount(){
  
}
 
 

 
  render() {
  
    return (
      <> 
 	<section class="section details">
		
		<div class="details__bg" data-bg="img/home/home__bg.jpg"></div>
		

		
		<div class="container">
			<div class="row">
				
				<div class="col-12">
					<h1 class="details__title"  dangerouslySetInnerHTML={{__html: this.props.title}}/>		
                    	</div>
				

				
				<div class="col-12 col-xl-6">
					<div class="card card--details">
						<div class="row">
							
							<div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-5">
								<div class="card__cover">
									<img src={this.props.poster} alt=""/>
								</div>
							</div>
							 
							<TelegramJoin/>
							<div class="col-12 col-sm-8 col-md-8 col-lg-9 col-xl-7">
								<div class="card__content">
									{/* <div class="card__wrap">
										<span class="card__rate"><i class="icon ion-ios-star"></i>8.4</span>

										<ul class="card__list">
											<li>HD</li>
											<li>16+</li>
										</ul>
									</div>

									<ul class="card__meta">

										<li><span>Genre:</span> 
										<a href="#">Adventure</a>
								 <a href="#">Animation</a>
								 <a href="#">Drama</a>
								 <a href="#">Family</a></li>
								 										<li><span>Release year:</span>1998-12-16</li>
										<li><span>Running time:</span>99</li>
										<li><span>Country:</span> <a href="#">USA</a> </li>
									</ul> */}

									<div class="card__description card__description--details" dangerouslySetInnerHTML={{__html: this.props.description}}>
									 </div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				
				
				<div class="col-12 col-xl-6">
					
              
					
				</div>
				
 
				 
				
			</div>

			<div className='pt-5' style={{"position":"relative"}}>
			<StreamingSource videoId={this.props.videoId}/>
			</div>


			
		</div>
	</section>
      </>
    )
  }
}

export default WatchMovie