import axios from 'axios'
import React, { Component } from 'react'
import Spinner from '../Spinner/Spinner'

export class StreamingSource extends Component {
    state = {
        data:"",
        loading:true,
        code:"",
        iframeloading:true,
        iframedata:"",
        sourcetitle:""
    }
    componentDidMount(){
        var code = ""
        if(this.state.loading){
            axios.get('?mflixid='+this.props.videoId)
            .then((response)=>{
                code = response.data[0].code;
     this.setState({
        data:response.data,
        loading:false,
        code:response.data[0].code
     })

     if(this.state.iframeloading){
        axios.get('?getvideo&id='+code)
        .then((response)=>{
            this.setState({
                iframedata:response.data,
                iframeloading:false
            })
           
 
})
  }
})
  }
}

onClickNewServer = (e)=>{
    this.setState({iframeloading:true})
    axios.get('?getvideo&id='+e.target.id)
    .then((response)=>{
        this.setState({
            iframedata:response.data,
            iframeloading:false,
            sourcetitle:e.target.innerText
        })
       

})
}

  render() {


        var sourceRender = ""
    var data = this.state.data
    if(!this.state.videosourceloading){
      sourceRender = Object.keys(data).map((e=>{
return <><div className='p-2'>
<button onClick={this.onClickNewServer} className='btn btn-primary' id={data[e].code}>{data[e].source}</button>
</div>
</>
      }))




    }
    var iframedata = this.state.iframedata;
      
    return (
      <>
      {this.state.loading?<Spinner/>:  <>
     
     <div className='row'>
     <div className='col-md-8'>
     { this.state.iframeloading? <Spinner/>:<div class="ratio ratio-16x9">
        {(this.state.sourcetitle== "VOE")?     <iframe className='w-100'   src={iframedata.data.link} title="Video" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" __idm_id__="4055041"></iframe> :    <iframe className='w-100'  sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation" src={iframedata.data.link} title="Video" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" __idm_id__="4055041"></iframe> }  
    
      </div> }
     
     
      <div className='text-light'  dangerouslySetInnerHTML={{__html: iframedata.html}}  />
     </div>
     
     <div className='col-md-4'>
         {sourceRender}
        </div>
     </div>
     
      {/* <div className='container d-flex justify-content-center align-items-center mb-5'>
      <div class="ratio ratio-16x9">
      <iframe className='w-100'  sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation" src={ iframedata.link} title="Video" allowfullscreen></iframe>
      </div>
      </div>
     <div className='container d-flex justify-content-center align-items-center'>
    
    
        <div className='row'>
         {sourceRender}
        </div>
     </div> */}
   
 
   {/* <h1 className='text-light'>{this.state.sourcetitle}</h1> */}
      </>}
     </>
    )
  }
}

export default StreamingSource